import axios from 'axios';
//const API_URL = 'http://localhost:8000/api/usersbe/';

let API_URL = ''

if(process.env.NODE_ENV === 'development') {
   API_URL = 'http://localhost:8000/api/usersbe/';
   
} else {
   API_URL =  '//api.admin-siamprai.com/api/usersbe/';

}

const updateUser = async (userData, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const res = await axios.put(API_URL + `${token._id}`, userData, config);
   return res.data;
};
const editRecommendUser = async (userData, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const res = await axios.patch(API_URL + `editRecommendUser`, userData, config);
   return res.data;
};



const getUsersPower = async ( token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };
    const res = await axios.get(API_URL + `getUsersPower`, config);
    return res.data;
};


const getDirector = async ( token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };
    const res = await axios.get(API_URL + `getDirector`, config);
    return res.data;
};


const findUserByUserId = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };
   const res = await axios.get(API_URL + `findUserByUserId/${id}`, config);
   return res.data;
};

const findUserByUserData = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };
   const res = await axios.get(API_URL + `findUserByUserData/${data}`, config);
   return res.data;
};

// Delete users by ids
const deleteUsers = async (ids, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const res = await axios.delete(API_URL + 'deleteusers', {
      headers: config.headers,
      data: { ids }, // Send data in the request body
   });

   return res.data;
};

// GET USER SUM PER MONTH
const getUsersSumPerMonth = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const res = await axios.get(API_URL + `getSumUserPerMonth`, config);

   return res.data;
};

const deleteUser = async (id,token) =>{
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const res = await axios.delete(API_URL + `deleteUser/${id}`, config);

   return res.data;
}

const BanUser = async (id,token) =>{
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };
   const res = await axios.get(API_URL + `UpdateBanUser/${id}`, config);

   return res.data;
}
const UnBanUser = async (id,token) =>{
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };
   const res = await axios.get(API_URL + `UpdateUnBanUser/${id}`, config);

   return res.data;
}

const userAdminService = {
   updateUser,
   deleteUsers,
   findUserByUserId,
   findUserByUserData,
   getUsersSumPerMonth,
   deleteUser,
   getUsersPower,
   editRecommendUser,
   BanUser,
   UnBanUser,
   getDirector
};
export default userAdminService;
