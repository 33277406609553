import React from 'react'
import DefaultLayout from '../components/DefualtLayout'

const BonusMatchingPage = () => {
  return (
    <DefaultLayout>
        
    </DefaultLayout>
  )
}

export default BonusMatchingPage