import React, { useEffect, useState, useRef } from 'react';
import DefaultLayout from '../components/DefualtLayout';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Highlighter from 'react-highlight-words';
import { BiSearch } from 'react-icons/bi';
import moment from 'moment';
import Loading from '../components/Loading';
import {
   approveDeposit,
   cancelDeposit,
   editDepostProducts,
   editFreeGift,
   getAllDeposits,
   reset,
   updateFreeGift,
} from '../features/deposit/depositSlice';
import {
   getAdminCenterPV,
   reset as resetEwallet,
} from '../features/ewallet/ewalletSlice';
import {
   Table,
   Input,
   Space,
   Button,
   Tag,
   Modal,
   Radio,
   Form,
   List,
   Statistic,
   Image,
   Checkbox,
   Row,
   Col,
   Select,
   InputNumber,
} from 'antd';

import styled from 'styled-components';
import { toast } from 'react-toastify';
import ErrorIcon from '../assets/icon/error-icon.png';
import { useMediaQuery } from 'react-responsive';
import commaNumber from 'comma-number';
import {
   getProducts2,
   reset as productReset,
} from '../features/product/productSlice';

const DepositsRequestPage = () => {
   const isMobile = useMediaQuery({ maxWidth: 539 });

   const { userInfo } = useSelector((state) => state.auth);

   const dispatch = useDispatch();
   const [form] = Form.useForm();
   const [loading2, setLoading2] = useState(false);

   const [isHolding, setIsHolding] = useState(false);
   const [isCancel, setISCancel] = useState(false);
   const [isSuccessed, setIsSuccessed] = useState(false);

   const [modalEdit, setModalEdit] = useState(false);
   const [depositSelected, setDepositSelected] = useState({});
   const [isAddMoreProduct, setIsAddMoreProduct] = useState(false);

   const [editedTotalProduct, setEditedTotalProduct] = useState([]);
   const [editedFreeGift, setEditedFreeGift] = useState([]);
   useEffect(() => {
      setEditedTotalProduct(depositSelected?.totalProduct || []);
      setEditedFreeGift(depositSelected?.freeGift || []);
   }, [depositSelected]);

   const handleTotalProductAmountChange = (index, newValue) => {
      const updatedProducts = editedTotalProduct.map((product, idx) =>
         idx === index ? { ...product, amount: newValue } : product
      );
      setEditedTotalProduct(updatedProducts);
   };

   const handleFreeGiftAmountChange = (index, newValue) => {
      const updatedGifts = editedFreeGift.map((gift, idx) =>
         idx === index ? { ...gift, amount: newValue } : gift
      );
      setEditedFreeGift(updatedGifts);
   };

   const handleSave = (e) => {
      e.preventDefault();
      const data = {};

      data.depId = depositSelected._id;
      data.totalProduct = totalQuantityPerProductArray2;

      console.log('data:', data);
      dispatch(editDepostProducts(data));
      //  setModalEdit(false);
   };

   const [freeGift, setFreeGift] = useState([{}]);
   const [modalFreeGift, setModalFreeGift] = useState(false);
   const [depId, setDepId] = useState({});

   const [confirmNumber, setConfirmNumber] = useState('');
   const [noteCancel, setNoteCancel] = useState('');
   const [data, setData] = useState(null);
   const [modal, setModal] = useState(false);
   const [cancelModal, setCancelModal] = useState(false);
   const [modalError, setModalError] = useState(false);

   const { deposits, isLoading, isError, message, isSuccess, isUpdateSuccess } =
      useSelector((state) => state.deposit);
   const { ewallet } = useSelector((state) => state.ewallet);
   const { products } = useSelector((state) => state.product);

   let holderProducts = [];

   if (products.length >= 1) {
      holderProducts = products.filter((e) => e.type === 'Holder');
   }

   const [productQuantities, setProductQuantities] = useState(
      products.reduce((acc, product) => {
         acc[product._id] = 0;
         return acc;
      }, {})
   );

   const handleInputChange = (e, productName) => {
      setProductQuantities({
         ...productQuantities,
         [productName]: parseInt(e.target.value, 10) || 0,
      });
   };

   const totalQuantityPerProduct = products.reduce((acc, product) => {
      const quantity = productQuantities[product.name] || 0;
      if (quantity !== 0) {
         return { ...acc, [product.name]: quantity };
      }
      return acc;
   }, {});

   const totalQuantityPerProductArray = Object.entries(
      totalQuantityPerProduct
   ).map(([productName, quantity]) => ({
      name: productName.trim(),
      amount: quantity,
   }));

   const handleUpdateFreeGift = (e) => {
      let data = totalQuantityPerProductArray;
      data._id = depId._id;

      const confirm = window.confirm('ยืนยันทำการอัพเดทรายการแถม');

      if (confirm) {
         dispatch(updateFreeGift(data));
      } else {
         window.location.reload();
      }
   };
   const handleUpdateFreeGift2 = (e) => {
      let data = totalQuantityPerProductArray;
      data._id = depId._id;

      const confirm = window.confirm('ยืนยันทำการอัพเดทรายการแถม');

      if (confirm) {
         console.log('data: ', data);
       //  dispatch(editFreeGift(data));
      } else {
         window.location.reload();
      }
   };

   //  console.log('productQuantities: ', productQuantities);

   let deposits2 = null;
   let depositsHolding = [];
   let depositsSuccess = [];
   let depositsCancel = [];
   if (deposits.deposits) {
      deposits2 = deposits.deposits;
      depositsHolding = deposits2.filter((e) => e.status === 'รออนุมัติ');
      depositsCancel = deposits2.filter((e) => e.status === 'ยกเลิก');
      depositsSuccess = deposits2.filter((e) => e.status === 'สำเร็จ');
   }

   useEffect(() => {
      if (isError) {
         toast.error(message, {
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
         });
      }

      if (isSuccess) {
         toast.success(message, {
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
         });
      }

      dispatch(getAllDeposits());
      dispatch(getAdminCenterPV());
      dispatch(getProducts2());

      return () => {
         dispatch(reset());
         dispatch(resetEwallet());
         dispatch(productReset());
      };
   }, [dispatch, isError]);

   // search
   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };
   const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
   };

   const [searchText, setSearchText] = useState('');
   const [searchedColumn, setSearchedColumn] = useState('');
   const searchInput = useRef(null);

   const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({
         setSelectedKeys,
         selectedKeys,
         confirm,
         clearFilters,
         close,
      }) => (
         <div
            style={{
               padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
         >
            <Input
               ref={searchInput}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
               }
               onPressEnter={() =>
                  handleSearch(selectedKeys, confirm, dataIndex)
               }
               style={{
                  marginBottom: 8,
                  display: 'block',
               }}
            />
            <Space>
               <Button
                  type='primary'
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<BiSearch />}
                  size='small'
                  style={{
                     width: 90,
                  }}
               >
                  Search
               </Button>
               <Button
                  onClick={() => clearFilters && handleReset(clearFilters)}
                  size='small'
                  style={{
                     width: 90,
                  }}
               >
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: (filtered) => (
         <BiSearch
            style={{
               color: filtered ? '#1890ff' : undefined,
            }}
         />
      ),
      onFilter: (value, record) => {
         if (dataIndex === 'userId') {
            return record.user.userId
               .toString()
               .toLowerCase()
               .includes(value.toLowerCase());
         } else {
            return record[dataIndex]
               .toString()
               .toLowerCase()
               .includes(value.toLowerCase());
         }
      },
      onFilterDropdownOpenChange: (visible) => {
         if (visible) {
            setTimeout(function () {
               if (searchInput.current) {
                  return searchInput.current.select();
               }
            }, 100);
         }
      },
      render: (text, record) =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{
                  backgroundColor: '#ffc069',
                  padding: 0,
               }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={
                  text ? record.user.userId.toString() : 'Something wrong'
               }
            />
         ) : dataIndex === 'user' ? (
            <span>{record.user.name}</span>
         ) : dataIndex === 'userId' ? (
            <span>{record.user.userId}</span>
         ) : (
            text
         ),
   });

   // search END

   const onChange = (e) => {
      setNoteCancel(e.target.value);
   };

   const updateStatusDepositAndTransfer = async (data) => {
      const data2 = {
         id: data.user.userId,
         deposit_id: data._id,
      };

      let newData = { ...data };

      newData.userId = data.user.userId;
      newData.confirmNumber = confirmNumber;

      if (isError) {
         toast.error(message);
         setModalError(true);
      } else {
         await dispatch(approveDeposit(newData));

         await dispatch(reset());

         await toast.success('ทำรายการสำเร็จ');
         await window.location.reload();
      }
   };

   const handlerCancelDeposit = async () => {
      let newData = { ...data };

      newData.note = noteCancel;
      const depositId = data._id;
      const id = data.user.userId;

      await dispatch(cancelDeposit({ id, depositId, noteCancel }));
      await toast.success('ทำรายการสำเร็จ');
      await window.location.reload();
   };

   const CheckStatus = (data) => {
      setData(data);
      setModal(true);
   };

   const column = [
      {
         title: 'เลขที่อ้างอิง',
         dataIndex: 'refNumber',
         ...getColumnSearchProps('refNumber'),
         render: (value, record) => (
            <div className='d-flex'>
               <Tag
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                     CheckStatus(record);
                  }}
                  color='blue'
               >
                  {value}
               </Tag>
            </div>
         ),
      },

      {
         title: 'ชื่อสมาชิก',
         dataIndex: 'user',
         align: 'center',
         key: 'user',
         render: (value) => <span> {value.name} </span>,
      },

      {
         title: 'รหัสสมาชิก',
         dataIndex: 'user',
         align: 'center',
         render: (value) => <span> {value.userId} </span>,
         ...getColumnSearchProps('userId'),
      },
      {
         title: 'วันที่ทำรายการ',
         align: 'center',
         dataIndex: 'createdAt',
         render: (value) =>
            isMobile ? (
               <>
                  <span>{moment(value).format('DD/MM/YYYY')}</span>
               </>
            ) : (
               <>
                  <span>{moment(value).format('DD-MM-YYYY HH:mm:ss')}</span>
               </>
            ),
      },
      {
         title: 'ยอดเงิน',
         dataIndex: 'amount',
         render: (value) => <Statistic value={value} />,
      },
      {
         title: 'วันที่อนุมัติ',
         dataIndex: 'dateApprove',
         responsive: ['sm'],
         render: (value) => (
            <>
               {value === null ? (
                  <>
                     <h5>ยังไม่อนุมัติ</h5>
                  </>
               ) : (
                  <>{moment(value).format('DD-MM-YYYY HH:mm:ss')}</>
               )}
            </>
         ),
      },
      {
         title: 'ของแถม',
         render: (record) => (
            <>
               {record.freeGift.length > 0 ? (
                  <Button
                     style={{ backgroundColor: '#bb4c02', color: 'white' }}
                     onClick={() => {
                        setDepositSelected(record);
                        setDepId(record);
                        setModalFreeGift(true);
                     }}
                  >
                     แก้ไขของแถม
                  </Button>
               ) : (
                  <Button
                     style={{ backgroundColor: '#14213D', color: 'white' }}
                     onClick={() => {
                        setDepositSelected(record);
                        setDepId(record);
                        setModalFreeGift(true);
                     }}
                  >
                     เลือกของแถม
                  </Button>
               )}
            </>
         ),
      },
      {
         title: 'สถานะ',
         dataIndex: 'status',
         defaultSortOrder: 'descend',
         sorter: (a, b) => a.status - b.status,
         render: (value, record) => {
            if (value === 'สำเร็จ') {
               return (
                  <div>
                     <Tag color='green'>
                        {value} <span>{record?.adminApproved?.username}</span>
                     </Tag>
                     {userInfo.userId === '1288872' && (
                        <Tag
                           color='volcano'
                           className='cursor-pointer'
                           onClick={() => {
                              setDepositSelected(record);
                              setModalEdit(true);
                           }}
                        >
                           แก้ไข
                        </Tag>
                     )}
                  </div>
               );
            } else if (value === 'รออนุมัติ') {
               return <Tag color='volcano'>{value} </Tag>;
            } else if (value === 'ยกเลิก') {
               return <Tag color='volcano'>{value}</Tag>;
            }
         },
      },
   ];

   const [selectedProduct, setSelectedProduct] = useState(null);
   const handleSelectChangeProducts = (value) => {
      setSelectedProduct(value);
   };

   const [quantities, setQuantities] = useState({});
   const handleQuantityChange = (productId, value) => {
      setQuantities({ ...quantities, [productId]: value });
   };

   const totalPV = Object.keys(quantities).reduce((acc, productId) => {
      const product = holderProducts.find((p) => p._id === productId);
      const quantity = quantities[productId];
      if (product && quantity) {
         return acc + product.pv * quantity;
      }
      return acc;
   }, 0);

   const totalQuantityPerProduct2 = holderProducts.reduce((acc, product) => {
      const quantity = quantities[product._id] || 0;
      if (quantity !== 0) {
         return { ...acc, [product.name]: quantity };
      }
      return acc;
   }, {});

   const totalQuantityPerProductArray2 = Object.entries(
      totalQuantityPerProduct2
   ).map(([productName, quantity]) => ({
      name: productName.trim(),
      amount: quantity,
   }));

   return (
      <DefaultLayout>
         {isLoading ? (
            <Loading />
         ) : (
            <>
               <div>
                  <h3> รายการเติม PV </h3>
                  <div
                     className={`d-flex ${
                        isMobile ? 'flex-column' : 'flex-row'
                     } gap-2`}
                  >
                     <Button
                        onClick={() => {
                           setIsSuccessed(false);
                           setIsHolding(false);
                           setISCancel(false);
                        }}
                     >
                        {' '}
                        รายการทั้งหมด{' '}
                     </Button>
                     <Button
                        onClick={() => {
                           setIsSuccessed(true);
                           setIsHolding(false);
                           setISCancel(false);
                        }}
                     >
                        {' '}
                        รายการอนุมัติสำเร็จ{' '}
                     </Button>

                     <Button
                        style={{
                           backgroundColor: isHolding ? '#ffca00' : '#14213D',
                           color: isHolding ? 'black' : 'white',
                        }}
                        onClick={() => {
                           setIsHolding(true);
                           setIsSuccessed(false);
                           setISCancel(false);
                        }}
                     >
                        {' '}
                        รายการรออนุมัติ{' '}
                     </Button>

                     <Button
                        style={{
                           backgroundColor: isCancel ? '#ffca00' : '#14213D',
                           color: isCancel ? 'black' : 'white',
                        }}
                        onClick={() => {
                           setISCancel(true);
                           setIsHolding(false);
                        }}
                        // onClick={() => navigate('/admin/deposits-cancel')}
                     >
                        {' '}
                        รายการยกเลิก{' '}
                     </Button>
                  </div>
                  <br />
                  <br />

                  <div className='text-center'>
                     <h5>
                        <span>พีวีคงเหลือ : </span>
                        <b>{commaNumber(ewallet.pv)}PV</b>
                     </h5>
                  </div>
                  <br />
                  <br />
                  {/* <Table /> */}
                  <div>
                     {deposits2 && (
                        <>
                           {!isSuccessed && !isHolding && !isCancel && (
                              <Table
                                 style={{
                                    maxWidth: isMobile ? '100%' : '90%',
                                    marginRight: 'auto',
                                    marginLeft: 'auto',
                                 }}
                                 dataSource={deposits2}
                                 columns={column}
                                 scroll={{
                                    x: 400,
                                 }}
                              />
                           )}
                           {isSuccessed && (
                              <Table
                                 style={{
                                    maxWidth: '90%',
                                    marginRight: 'auto',
                                    marginLeft: 'auto',
                                 }}
                                 dataSource={
                                    isSuccessed === true
                                       ? depositsSuccess
                                       : deposits2
                                 }
                                 columns={column}
                                 scroll={{
                                    x: 400,
                                 }}
                              />
                           )}
                           {isHolding && (
                              <Table
                                 style={{
                                    maxWidth: '90%',
                                    marginRight: 'auto',
                                    marginLeft: 'auto',
                                 }}
                                 dataSource={
                                    isHolding === true
                                       ? depositsHolding
                                       : deposits2
                                 }
                                 columns={column}
                                 scroll={{
                                    x: 400,
                                 }}
                              />
                           )}
                           {isCancel && (
                              <Table
                                 style={{
                                    maxWidth: '90%',
                                    marginRight: 'auto',
                                    marginLeft: 'auto',
                                 }}
                                 dataSource={
                                    isCancel === true
                                       ? depositsCancel
                                       : deposits2
                                 }
                                 columns={column}
                                 scroll={{
                                    x: 400,
                                 }}
                              />
                           )}
                        </>
                        // <Table
                        //    style={{
                        //       maxWidth: '90%',
                        //       marginRight: 'auto',
                        //       marginLeft: 'auto',
                        //    }}
                        //    dataSource={
                        //       isHolding === true ? depositsHolding : deposits2
                        //    }
                        //    columns={column}
                        // />
                     )}
                  </div>
               </div>
               <Modal
                  title='ทำรายการไม่สำเร็จ'
                  visible={modalError}
                  footer={false}
                  onCancel={() => setModalError(false)}
               >
                  <div className='text-center'>
                     <h4>
                        <b>ทำรายการไม่สำเร็จ</b>
                     </h4>
                     <h5>{message} </h5>
                     <Image src={ErrorIcon} width='50%' preview={false} />
                  </div>
               </Modal>
               <Modal
                  title='ยกเลิกรายการ'
                  visible={cancelModal}
                  footer={false}
                  onCancel={() => setCancelModal(false)}
               >
                  <Radio.Group onChange={onChange} value={noteCancel}>
                     <Space direction='vertical'>
                        <Radio value='ยอดเงินไม่ตรงกับที่แจ้ง'>
                           ยอดเงินไม่ตรงกับที่แจ้ง
                        </Radio>
                        <Radio value='ใช้ Slip ซ้ำ'>ใช้ Slip ซ้ำ</Radio>
                        <Radio value='ไม่ใช่บัญชีบริษัท'>
                           ไม่ใช่บัญชีบริษัท
                        </Radio>
                        <Radio value='ภาพไม่ชัด'>ภาพไม่ชัด</Radio>
                        <Radio value='ไม่ใช่ภาพ Slip'>ไม่ใช่ภาพ Slip</Radio>
                        <Radio value={6}>
                           อื่นๆ
                           {noteCancel === 6 ? (
                              <Input
                                 onChange={(e) => setNoteCancel(e.target.value)}
                                 style={{
                                    width: 100,
                                    marginLeft: 10,
                                 }}
                              />
                           ) : null}
                        </Radio>
                     </Space>
                  </Radio.Group>
                  <br />
                  <Button
                     onClick={handlerCancelDeposit}
                     block
                     className='btn btn-danger mt-4'
                  >
                     ยกเลิกรายการ
                  </Button>
               </Modal>
               <Modal
                  title='ยืนยันอนุมัติรายการ'
                  width={800}
                  visible={modal}
                  footer={false}
                  onCancel={() => setModal(false)}
               >
                  {data != null && (
                     <div className='d-flex flex-column'>
                        <Form
                           form={form}
                           name='basic'
                           labelCol={{
                              span: 6,
                           }}
                           wrapperCol={{
                              span: 18,
                           }}
                        >
                           <Form.Item label='หมายเลขอ้างอิง'>
                              <TextStyle>{data.refNumber}</TextStyle>
                           </Form.Item>
                           <Form.Item label='วันที่ทำรายการ'>
                              <TextStyle>
                                 {moment(data.date).format(
                                    'MMMM Do YYYY, h:mm:ss a'
                                 )}
                              </TextStyle>
                           </Form.Item>
                           <Form.Item label='ยอดเงิน'>
                              <TextStyle>
                                 {' '}
                                 <Statistic value={data.amount} />{' '}
                              </TextStyle>
                           </Form.Item>
                           <Form.Item label='PV'>
                              <TextStyle>
                                 <Statistic value={data.totalPv} />
                              </TextStyle>
                           </Form.Item>
                           <Form.Item label='หมายเลขยืนยัน'>
                              <Input
                                 maxLength={6}
                                 onChange={(e) =>
                                    setConfirmNumber(e.target.value)
                                 }
                              />
                           </Form.Item>
                        </Form>
                        <div>
                           <List
                              header={<strong>รายการสินค้าที่สั่งซื้อ</strong>}
                              bordered
                              dataSource={data.totalProduct}
                              renderItem={(item) => (
                                 <List.Item>
                                    <>
                                       <span>{item.name}</span> :{' '}
                                       <span>{item.amount} ชิ้น</span>
                                    </>{' '}
                                 </List.Item>
                              )}
                           />
                        </div>

                        <div
                           className='text-center'
                           style={{ marginBottom: '20px', marginTop: '20px' }}
                        >
                           {isMobile ? (
                              <Image
                                 height={300}
                                 width='60%'
                                 src={data.imgSlip.url}
                              />
                           ) : (
                              <Image
                                 height={700}
                                 width='60%'
                                 src={data.imgSlip.url}
                              />
                           )}
                        </div>

                        <div className='d-flex flex-column gap-3 justify-content-center'>
                           {data.status === 'สำเร็จ' ||
                              (data.status === 'ยกเลิก' && null)}
                           {data.status === 'รออนุมัติ' && (
                              <>
                                 <Button
                                    block
                                    type='primary'
                                    loading={loading2}
                                    className='btn-success'
                                    onClick={() =>
                                       updateStatusDepositAndTransfer(data)
                                    }
                                 >
                                    {' '}
                                    อนุมัติ
                                 </Button>
                                 <Button
                                    className='btn-danger'
                                    onClick={() => {
                                       setCancelModal(true);
                                    }}
                                 >
                                    ยกเลิก
                                 </Button>
                              </>
                           )}
                        </div>
                     </div>
                  )}
               </Modal>
               <Modal
                  title={`รายการของแถม - ${depositSelected?.user?.name}`}
                  width={800}
                  open={modalFreeGift}
                  onOk={(e) => {
                     handleUpdateFreeGift(e);
                  }}
                  footer={false}
                  onCancel={() => {
                     setDepId({});
                     setDepositSelected({});
                     setIsAddMoreProduct(false)
                     setModalFreeGift(false);
                  }}
               >
                  <div>
                     <>
                        {depositSelected?.freeGift?.length > 0 ? (
                           <>
                              {depositSelected?.freeGift?.map((e, index) => (
                                 <Row
                                    key={index}
                                    gutter={16}
                                    style={{ marginBottom: 16 }}
                                 >
                                    <Col span={12}>
                                       <Input value={e.name} readOnly />
                                    </Col>
                                    <Col span={12}>
                                       <Input
                                          value={e.amount}
                                          onChange={(event) =>
                                             handleTotalProductAmountChange(
                                                index,
                                                event.target.value
                                             )
                                          }
                                       />
                                    </Col>
                                 </Row>
                              ))}

                              <Button
                                 type='primary'
                                 className='btn-primary'
                                 onClick={() => setIsAddMoreProduct(true)}
                              >
                                 แก้ไข
                              </Button>

                              {isAddMoreProduct && (
                                 <>
                                    <div className='d-flex flex-column'>
                                       <Form
                                          style={{ width: '50%' }}
                                          name='basic'
                                          labelCol={{
                                             span: 12,
                                          }}
                                          wrapperCol={{
                                             span: 12,
                                          }}
                                       >
                                          {products.map((product) => {
                                             return (
                                                <Form.Item
                                                   key={product.name}
                                                   label={product.name}
                                                >
                                                   <Input
                                                      defaultValue={0}
                                                      value={
                                                         productQuantities[
                                                            product.name
                                                         ]
                                                      }
                                                      onChange={(e) =>
                                                         handleInputChange(
                                                            e,
                                                            product.name
                                                         )
                                                      }
                                                   />
                                                </Form.Item>
                                             );
                                          })}
                                       </Form>
                                       {userInfo.isSuperAdmin !== true && (
                                          <>
                                          <Button
                                          onClick={(e) => {
                                             handleUpdateFreeGift2(e);
                                          }}
                                          style={{
                                             backgroundColor: '#14213D',
                                             color: 'white',
                                          }}
                                       >
                                          ยืนยัน2
                                       </Button>
                                          </>
                                       )}
                                    </div>
                                   
                                 </>
                              )}
                           </>
                        ) : (
                           <div className='d-flex flex-column'>
                              <Form
                                 style={{ width: '50%' }}
                                 name='basic'
                                 labelCol={{
                                    span: 12,
                                 }}
                                 wrapperCol={{
                                    span: 12,
                                 }}
                              >
                                 {products.map((product) => {
                                    return (
                                       <Form.Item
                                          key={product.name}
                                          label={product.name}
                                       >
                                          <Input
                                             defaultValue={0}
                                             value={
                                                productQuantities[product.name]
                                             }
                                             onChange={(e) =>
                                                handleInputChange(
                                                   e,
                                                   product.name
                                                )
                                             }
                                          />
                                       </Form.Item>
                                    );
                                 })}
                              </Form>
                            {userInfo.isSuperAdmin !== true && (
                              <>
                                <Button
                              onClick={(e) => {
                                 handleUpdateFreeGift(e);
                              }}
                                 style={{
                                    backgroundColor: '#14213D',
                                    color: 'white',
                                 }}
                              >
                                 ยืนยัน
                              </Button>
                              </>
                            )}
                           </div>
                        )}
                     </>
                  </div>
               </Modal>
               <Modal
                  title={`แก้ไขข้อมูลสินค้า - ${depositSelected?.user?.name}`}
                  width={800}
                  open={modalEdit}
                  onCancel={() => {
                     setDepositSelected({});
                     setIsAddMoreProduct(false);
                     setModalEdit(false);
                  }}
                  footer={false}
                  // onOk={handleSave}
               >
                  <div>
                     <div className='d-flex flex-column gap-3'>
                        <h5>
                           <b>รายการสินค้าที่เลือก</b>
                        </h5>
                        <h5 className='text-danger'>
                           <span>จำนวนพีวี</span> :
                           <b>{commaNumber(depositSelected?.totalPv)}</b>
                        </h5>

                        <h5>
                           <span>พีวีปัจจุบัน</span> :
                           <b>{commaNumber(totalPV.toFixed(0))}</b>
                        </h5>

                        {depositSelected?.totalProduct?.map((e, index) => (
                           <Row
                              key={index}
                              gutter={16}
                              style={{ marginBottom: 16 }}
                           >
                              <Col span={12}>
                                 <Input value={e.name} readOnly />
                              </Col>
                              <Col span={12}>
                                 <Input
                                    value={e.amount}
                                    onChange={(event) =>
                                       handleTotalProductAmountChange(
                                          index,
                                          event.target.value
                                       )
                                    }
                                 />
                              </Col>
                           </Row>
                        ))}
                        <Button
                           type='primary'
                           onClick={() => setIsAddMoreProduct(true)}
                        >
                           +
                        </Button>
                        {isAddMoreProduct && (
                           <>
                              {holderProducts.map((e, index) => (
                                 <Row
                                    key={index}
                                    gutter={16}
                                    style={{ marginBottom: 16 }}
                                 >
                                    <Col span={12}>
                                       <Input value={e.name} readOnly />
                                    </Col>
                                    <Col span={12}>
                                       <InputNumber
                                          placeholder='0'
                                          min={0}
                                          defaultValue={quantities[e._id] || 0}
                                          onChange={(value) =>
                                             handleQuantityChange(
                                                e._id,
                                                value,
                                                e.name
                                             )
                                          }
                                       />
                                    </Col>
                                 </Row>
                              ))}
                           </>
                        )}
                     </div>
                     {totalPV >= depositSelected.totalPv && (
                      <>
                      {userInfo.isSuperAdmin !== true (
                          <Button
                          style={{
                             backgroundColor: '#14213D',
                             color: 'white',
                          }}
                          onClick={(e) => handleSave(e)}
                       >
                          ยืนยัน
                       </Button>
                      )}
                      </>
                     )}
                  </div>
               </Modal>
            </>
         )}
      </DefaultLayout>
   );
};

const TextStyle = styled.p`
   margin: 0 !important;
   border-bottom: 1px dashed;
`;

export default DepositsRequestPage;
