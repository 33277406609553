import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userService from './userService';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

const initialState = {
   users: [],
   usersRegister: [],
   centers: [],
   user: {},
   upline: {},
   UpTopIdUplineUser: {},
   getUpTopSuccess: false,
   check: null,
   isError: false,
   isSuccess: false,
   registerSuccess: false,
   isUpdateCenterError: false,
   isUpdateCenterSuccess: false,
   isLoading: false,
   message: '',
};



// GET UpTopIdUplineUser
export const getUserRegister = createAsyncThunk(
   'user/getUserRegister',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.getUserRegister(data,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);


// GET ALL USERS
export const getUsers = createAsyncThunk(
   'user/getUsers',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.getUsers(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// GET ALL CENTERS
export const getCenters = createAsyncThunk(
   'user/getCenters',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.getAllCenters(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// UPDATE USER
export const updateUserToCenter = createAsyncThunk(
   'user/updateUserToCenter',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.userToCenter(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);



const userSlice = createSlice({
   name: 'user',
   initialState,
   reducers: {
      reset: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(getUsers.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getUsers.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.users = action.payload;
         })
         .addCase(getUsers.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getUserRegister.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getUserRegister.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.usersRegister = action.payload;
         })
         .addCase(getUserRegister.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(updateUserToCenter.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(updateUserToCenter.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isUpdateCenterSuccess = true;
            state.user = action.payload;
         })
         .addCase(updateUserToCenter.rejected, (state, action) => {
            state.isLoading = false;
            state.isUpdateCenterError = true;
            state.user = action.payload;
         })
         .addCase(getCenters.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getCenters.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.centers = action.payload;
         })
         .addCase(getCenters.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action.payload;
         });
       
   },
});
export const { reset } = userSlice.actions;
export default userSlice.reducer;
