import axios from 'axios';

//const API_URL = 'http://localhost:8000/api/usersbe/';

let API_URL = ''
let API_URL2 = ''
if(process.env.NODE_ENV === 'development') {
   API_URL = 'http://localhost:8000/api/usersbe/';
   API_URL2 = 'http://localhost:8000/api/admin/';
} else {
   API_URL =  '//api.admin-siamprai.com/api/usersbe/';
   API_URL2 =  '//api.admin-siamprai.com/api/admin/';

}


const createAdmin = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const res = await axios.post(
      API_URL2 + 'createAdmin/',
      data,
      config
   );
   return res.data;
};

const editAdmin = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const res = await axios.patch(
      API_URL2 + 'editAdmin/',
      data,
      config
   );
   return res.data;
};


const getAdmins = async ( token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const res = await axios.get(
      API_URL2 + 'getAdmins/',
      config
   );
   return res.data;
};

const adminUpdateUser = async (userData, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const res = await axios.patch(
      API_URL + 'updateUser/' + `${userData.userId}`,
      userData,
      config
   );
   return res.data;
};

const adminUpdateUserPassword = async (userData, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };
   console.log(userData);
   const res = await axios.patch(
      API_URL + 'adminUpdateUserPassword/' + `${userData.userId}`,
      userData,
      config
   );
   return res.data;
};




const userAdminService = {
   adminUpdateUser,
   adminUpdateUserPassword,
   createAdmin,
   getAdmins,
   editAdmin
};
export default userAdminService;
