import './App.css';
import React from 'react';
import {
   BrowserRouter as Router,
   Routes,
   Route,
   Navigate,
   Outlet
} from 'react-router-dom';

import { ToastContainer } from 'react-toastify';


import ExportBonusPerDay from './page/ExportBonusPerDay';
import CentersPage from './page/CentersPage';

import SettingPercentage from './page/SettingPercentage';

import ProductListPage from './page/ProductListPage';
import ExportBonusPerDayDetail from './page/ExportBonusPerDayDetail';

import ProductPromotion from './page/ProductPromotion';

import ExportBonusPerson from './page/ExportBonusPerson';
import ExportMatchingPerDay from './page/ExportMatchingPerDay';
import ExportMatching from './page/ExportMatching';
import ExportBonusMatchingSummary from './page/ExportBonusMatchingSummary';
import DepositsRequestPage from './page/DepositsRequestPage';
import DepositDetail from './page/DepositDetailPage';
import Home from './page/Home';
import LoginPage from './page/LoginPage';
import MemberRegistration from './page/MemberRegistration';
import OrderLists from './page/OrderLists';

import ExportBonusSelectDay from './page/ExportBonusSelectDay';
import MemberPower from './page/MemberPower';
import AdminsPage from './page/AdminsPage';
import SpecialBonusPage from './page/SpecialBonusPage';
import StocksPage from './page/StocksPage';
import BonusMatchingPage from './page/BonusMatchingPage';
import SummaryCommissionUsersPerMonth from './page/SummaryCommissionUsersPerMonth';
import SummaryBonusPerson from './page/SummaryBonusPerson';
import ExportBonusSelectDayKhmer from './page/ExportBonusSelectDayKhmer';

// KH
import HomeKh from './pageKhmer/Home';
import ExportBonusSelectDayKh from './pageKhmer/ExportBonusSelectDayKh';
import DepositsRequestPageKh from './pageKhmer/DepositRequestPageKh'
import MemberKh from './pageKhmer/MemberPageKh'
// KH

function App() {
   return (
      <>
         <ToastContainer />

         <Routes>
      {/* Public Routes */}
      <Route path="/" element={<LoginPage />} />
      <Route path="login" element={<LoginPage />} />

      {/* Protected Routes */}
      <Route element={<ProtectedRoute />}>
        {/* Thai Language Routes */}
        <Route element={<ProtectedThaiRoute />}>
          <Route path="admin/dashboard" element={<Home />} />
          <Route path="admin/deposit/:id" element={<DepositDetail />} />
          <Route path="admin/deposits" element={<DepositsRequestPage />} />
          <Route path="admin/setting" element={<SettingPercentage />} />
          <Route path="admin/centers" element={<CentersPage />} />
          <Route path="admin/productlist" element={<ProductListPage />} />
          <Route path="admin/stocks" element={<StocksPage />} />
          <Route path="admin/productlistpromotion" element={<ProductPromotion />} />
          <Route path="admin/exportmatching" element={<ExportMatching />} />
          <Route path="admin/exportBonusPerDay" element={<ExportBonusPerDay />} />
          <Route path="admin/stocksHistories" element={<SpecialBonusPage />} />
          <Route path="admin/exportBonusSelectDay" element={<ExportBonusSelectDay />} />
          <Route path="admin/exportBonusSelectDayKhmer" element={<ExportBonusSelectDayKhmer />} />
          <Route path="admin/summaryCommissionUsersPerMonth" element={<SummaryCommissionUsersPerMonth />} />
          <Route path="admin/SummaryBonusPerson/:id" element={<SummaryBonusPerson />} />
          <Route path="admin/bonusMatching" element={<BonusMatchingPage />} />
          <Route path="admin/exportmatchingPerDay" element={<ExportMatchingPerDay />} />
          <Route path="admin/exportmatchingPerDay/:date" element={<ExportMatchingPerDay />} />
          <Route path="admin/exportBonusMatchingSummary/:id/:date/:depth" element={<ExportBonusMatchingSummary />} />
          <Route path="admin/exportBonusPerDayDetail/:date" element={<ExportBonusPerDayDetail />} />
          <Route path="admin/exportBonusPerson/:cardId/:date" element={<ExportBonusPerson />} />
          <Route path="admin/memberRegistration" element={<MemberRegistration />} />
          <Route path="admin/memberPower" element={<MemberPower />} />
          <Route path="admin/orderLists" element={<OrderLists />} />
        </Route>

        {/* Khmer Language Routes */}
        <Route element={<ProtectedKhmerRoute />}>
          <Route path="admin/dashboard/kh" element={<HomeKh />} />
          <Route path="admin/exportBonusSelectDayKhmer/kh" element={<ExportBonusSelectDayKh />} />
          <Route path="admin/deposits/kh" element={<DepositsRequestPageKh />} />
          <Route path="admin/memberRegistration/kh" element={<MemberKh />} />
          {/* Add other Khmer-specific routes here if needed */}
        </Route>

        {/* Admin Routes */}
        <Route path="/admin/admins" element={<AdminsPage />} />
      </Route>

      {/* Catch-all route */}
      {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
    </Routes>

  

      </>
   );
}

export default App;

export function ProtectedKhmerRoute() {
   const userInfo = JSON.parse(localStorage.getItem('userInfo'));
   console.log('userInfo:' ,userInfo);
   
   if (userInfo?.isKhmer) {
      return <Outlet />;
   } else {
      return <Navigate to='/admin/dashboard' />;
   }
}

export function ProtectedThaiRoute() {
   const userInfo = JSON.parse(localStorage.getItem('userInfo'));
   console.log('userInfo :', userInfo);

   if (userInfo?.isThai) {
      return <Outlet />;
   } else {
      return <Navigate to='/admin/dashboard/kh' />;
   }
}
export function ProtectedRoute() {
   
   if (localStorage.getItem('userInfo')) {
      return <Outlet />;
   } else {
      return <Navigate to='/login' />;
   }
}


