import React, { useEffect, useState } from 'react';
import DefaultLayout from '../components/DefualtLayout';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../components/Loading';
import styled from 'styled-components';

import { Button, Statistic, Table } from 'antd';
import moment from 'moment';
import { getMatchingData, reset } from '../features/commission/commissionSlice';

import commaNumber from 'comma-number';
import { CSVLink } from 'react-csv';

const ExportMatching = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const location = useLocation();
   const [loadingPage, setLoadingPage] = useState(true);

   const { mathcing, isLoading } = useSelector((state) => state.commission);

   const data = mathcing.dataResult;

   const [currentMonth, setCurrentMonth] = useState('');
   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

   const monthOptions = [
      { value: 'มกราคม', label: 'มกราคม', num: 1 },
      { value: 'กุมภาพันธ์', label: 'กุมภาพันธ์', num: 2 },
      { value: 'มีนาคม', label: 'มีนาคม', num: 3 },
      { value: 'เมษายน', label: 'เมษายน', num: 4 },
      { value: 'พฤษภาคม', label: 'พฤษภาคม', num: 5 },
      { value: 'มิถุนายน', label: 'มิถุนายน', num: 6 },
      { value: 'กรกฎาคม', label: 'กรกฎาคม', num: 7 },
      { value: 'สิงหาคม', label: 'สิงหาคม', num: 8 },
      { value: 'กันยายน', label: 'กันยายน', num: 9 },
      { value: 'ตุลาคม', label: 'ตุลาคม', num: 10 },
      { value: 'พฤศจิกายน', label: 'พฤศจิกายน', num: 11 },
      { value: 'ธันวาคม', label: 'ธันวาคม', num: 12 },
   ];

   const handleMonthChange = (selectedOption) => {
      setCurrentMonth(selectedOption.value);
      setIsDropdownOpen(false);

  
   };

   useEffect(() => {
      const now = new Date();
      const month = now.toLocaleString('th-TH', { month: 'long' });
      //  setCurrentMonth(month);
   }, []);

   console.log('currentMonth: ', currentMonth);

   useEffect(() => {
      if (currentMonth !== '') {
            dispatch(getMatchingData(currentMonth));         
      }

      // return () => {
      //    dispatch(reset());
      // };
   }, [dispatch, currentMonth]);

   useEffect(() => {
      if (loadingPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }

      // dispatch(getMatchingData(date));
   }, []);

   function sumCommissions(commissions) {
      return commissions.reduce(
         (sum, commission) => sum + commission.amount * 0.05,
         0
      );
   }

   // Process the data
   const monthlySummary = data?.map((user) => {
      const level1Total = sumCommissions(user?.level1Commission);
      const level2Total = sumCommissions(user?.level2Commission);
      const totalCommission = level1Total + level2Total;

      return {
         userId: user.user.userId,
         name: user.user.name,
         position: user.user.position,
         bank: user.user.bankData.bank,
         bankAccount: user.user.bankData.bankAccount,
         level1Commission: parseFloat(level1Total.toFixed(2)),
         level2Commission: parseFloat(level2Total.toFixed(2)),
         totalCommission: parseFloat(totalCommission.toFixed(2)),
      };
   });

   // If you want a grand total across all users
   // const grandTotal = monthlySummary?.reduce((total, user) => total + user.totalCommission, 0);

   const columns = [
      {
         title: 'ลำดับ',
         dataIndex: 'depth',
         key: 'depth',
         render: (text, record, index) => <span>{index + 1}</span>,
      },
      {
         title: 'รหัสสมาชิก',
         dataIndex: 'userId',
      },
      {
         title: 'ชื่อ',
         dataIndex: 'name',
      },
      {
         title: 'โบนัสแมชชิ่ง',
         dataIndex: 'totalCommission',
         render: (value) => <span>{commaNumber(value)}</span>,
      },
   ];

   const headers = [
      { label: 'ตำแหน่ง', key: 'position' },
      { label: 'ชื่อ-สกุล', key: 'name', width: 30 },
      { label: 'รหัสสมาชิก', key: 'userId' },
      { label: 'โบนัสแมชชิ่ง', key: 'totalCommission' },
      { label: 'ธนาคาร', key: 'bank' },
      { label: 'เลขบัญชี', key: 'bankAccount' },

   ];

   return (
      <DefaultLayout>
         {isLoading ? (
            <Loading />
         ) : (
            <>
               <div>
                  <h5 className='p-4 text-center'>
                     {' '}
                     <b
                        className='cursor-pointer'
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                     >
                        {currentMonth === ''
                           ? 'กรุณาเลือกเดือน'
                           : `รายงานสรุปแมชชิ่งรายเดือน : ${currentMonth}`}
                     </b>
                  </h5>

                  {isDropdownOpen && (
                     <DropdownContainer isOpen={isDropdownOpen}>
                        <SelectDiv>
                           <ul>
                              {monthOptions.map((e) => (
                                 <li
                                    key={e.value}
                                    onClick={() => handleMonthChange(e)}
                                 >
                                    {e.value}
                                 </li>
                              ))}
                           </ul>
                        </SelectDiv>
                     </DropdownContainer>
                  )}
               </div>
               <br />
               {monthlySummary && (
                  <Button
                     style={{ color: 'black' }}
                     disabled={monthlySummary?.length < 1}
                  >
                     <CSVLink
                        filename={`สรุปโบนัสแมชชิ่งเดือน${currentMonth}.csv`}
                        data={monthlySummary}
                        headers={headers}
                     >
                        Export Data
                     </CSVLink>
                  </Button>
               )}
               ิ<br />
               <Table columns={columns} dataSource={monthlySummary} />
            </>
         )}
      </DefaultLayout>
   );
};

export default ExportMatching;

const DropdownContainer = styled.div`
   // position: absolute;
   right: 0;
   top: 50px; /* Adjust based on your needs */
   z-index: 1000;
   max-height: ${(props) =>
      props.isOpen ? '500px' : '0'}; /* Adjust 200px based on content height */
   overflow: hidden;

   transition-timing-function: ease-in;
   transition: 0.2s;
`;

const SelectDiv = styled.div`
   background-color: white;
   border: 1px solid #ccc;
   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
   border-radius: 8px;
   padding: 20px;
   transition: 0.25s;
   transition-timing-function: ease-out;

   transform: translateY(0);
   ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
         padding: 8px 12px;
         cursor: pointer;
         &:hover {
            background-color: #f0f0f0;
         }
      }
   }
`;
