import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userAdminService from './userAdminService';
import Cookies from 'js-cookie';


const initialState = {
   users: [],
   centers: [],
   adminsUser: [],
   user: {},
   upline: {},
   isError: false,
   isSuccess: false,
   isAdminUpdatesuccess: false,
   isLoading: false,
   message: '',
};


export const getAdmins = createAsyncThunk(
   'user/getAdmins',
   async ( thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userAdminService.getAdmins( token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);


export const createAdmin = createAsyncThunk(
   'user/createAdmin',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userAdminService.createAdmin(data, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// UPDATE USER
export const adminUpdateUser = createAsyncThunk(
   'user/adminUpdateUser',
   async (userData, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userAdminService.adminUpdateUser(userData, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const adminUpdateUserPassword = createAsyncThunk(
   'user/adminUpdateUserPassword',
   async (userData, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userAdminService.adminUpdateUserPassword(userData, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const editAdmin = createAsyncThunk(
   'user/editAdmin',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userAdminService.editAdmin(data, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);



const userAdminSlice = createSlice({
   name: 'userAdmin',
   initialState,
   reducers: {
      reset: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(adminUpdateUser.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(adminUpdateUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isAdminUpdatesuccess = true;
            state.user = action.payload;
         })
         .addCase(adminUpdateUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(editAdmin.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(editAdmin.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
         
         })
         .addCase(editAdmin.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getAdmins.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getAdmins.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.adminsUser = action.payload;
         })
         .addCase(getAdmins.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(createAdmin.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(createAdmin.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
        
         })
         .addCase(createAdmin.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(adminUpdateUserPassword.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(adminUpdateUserPassword.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isAdminUpdatesuccess = true;
            state.user = action.payload;
         })
         .addCase(adminUpdateUserPassword.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         });
   },
});

export const { reset } = userAdminSlice.actions;
export default userAdminSlice.reducer;
