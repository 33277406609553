// import axios from 'axios';
import axios from '../../utils/axiosConfig';
import Cookies from 'js-cookie';

// const API_URL = 'http://localhost:5000/api/users/';
// const API_URL =  '//siamprai-server-khemer.proen.app.ruk-com.cloud/api/users/';

let API_URL = ''
if(process.env.NODE_ENV === 'development') {
   API_URL = 'http://localhost:8000/api/users/';
} else {
   API_URL =  '//api.admin-siamprai.com/api/users/';

}



// Register user
const register = async (userData) => {
   const response = await axios.post(API_URL, userData);

   if (response.data) {
      localStorage.setItem('userInfo', JSON.stringify(response.data));
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
   }

   return response.data;
};

// Login user
const login = async (userData) => {
 const response = await axios.post(API_URL + 'login', userData, {withCredentials: true} );

   if (response.data) {
      localStorage.setItem('userInfo', JSON.stringify(response.data));
   }

   return response.data;
};

// Logout user
const logout = async () => {

   localStorage.clear();
   Cookies.remove('token');
   axios.defaults.headers.common['Authorization'] = '';
};

const authService = {
   register,
   logout,
   login,
};

export default authService;
