import React, { useEffect } from 'react';
import DefaultLayout from '../components/DefualtLayout';
import { Button, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { SummaryCommissionUsersPerWeek } from '../features/adminFeatures/commission/commissionAdminSlice';
import { Container } from 'react-bootstrap';
import commaNumber from 'comma-number';
import { CSVLink } from 'react-csv';
import { Link, useNavigate } from 'react-router-dom';

const SummaryCommissionUsersPerMonth = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const { userInfo } = useSelector((state) => state.auth);
   const { commissions } = useSelector((state) => state.commissionAdmin);

   useEffect(() => {

      if(userInfo.isSuperAdmin){
         navigate('/admin/dashboard')
      }
      dispatch(SummaryCommissionUsersPerWeek());
   }, [dispatch]);

   const sortedCommissions = [...commissions].sort((a, b) => {
      const bankA = a?.user?.bankData?.bank?.toLowerCase() ?? '';
      const bankB = b?.user?.bankData?.bank?.toLowerCase() ?? '';
      return bankA.localeCompare(bankB);
   });

   const columns = [
      {
         title: 'รหัสสมาชิก',
         dataIndex: 'user',
         render: (value) => (
          
               <span> {value.userId} </span>
       
         ),
      },
      {
         title: 'ชื่อ',
         dataIndex: 'user',
         render: (value) => (
            <Link to={`/admin/SummaryBonusPerson/${value._id}`}>
               <span> {value.name} </span>
            </Link>
         ),
      },
      {
         title: 'ข้อมูลส่วนตัว',
         children: [
            {
               title: 'ธนาคาร',
               dataIndex: 'user',
               render: (value) => <span> {value.bankData.bank} </span>,
               //  sorter: (a, b) => {
               //   const bankA = a?.user?.bankData?.bank?.toLowerCase() ?? '';
               //   const bankB = b?.user?.bankData?.bank?.toLowerCase() ?? '';
               //   return bankA.localeCompare(bankB);
               // },
            },
            {
               title: 'หมายเลขบัญชี',
               dataIndex: 'user',
               render: (value) => <span> {value.bankData.bankAccount} </span>,
            },
            {
               title: 'หมายเลขบัตรประชาชน',
               dataIndex: 'user',
               render: (value) => <span> {value.cardId} </span>,
            },
         ],
      },
      {
         title: 'รายได้เดือน ... 2567',
         children: [
            {
               title: 'สัปดาห์ที่ 1',
               dataIndex: 'week1',
               render: (value, record) => {
                  return {
                     props: {
                        style: { background: '#bbe4e9' },
                     },
                     children: <span>{commaNumber(value)}</span>,
                  };
               },
            },
            {
               title: 'สัปดาห์ที่ 2',
               dataIndex: 'week2',
               render: (value, record) => {
                  return {
                     props: {
                        style: { background: '#79c2d0' },
                     },
                     children: <span>{commaNumber(value)}</span>,
                  };
               },
            },
            {
               title: 'สัปดาห์ที่ 3',
               dataIndex: 'week3',
               render: (value, record) => {
                  return {
                     props: {
                        style: { background: '#53a8b6' },
                     },
                     children: <span>{commaNumber(value)}</span>,
                  };
               },
            },
            {
               title: 'สัปดาห์ที่ 4',
               dataIndex: 'week4',
               render: (value, record) => {
                  return {
                     props: {
                        style: { background: '#5585b5' },
                     },
                     children: <span>{commaNumber(value)}</span>,
                  };
               },
            },
            {
               title: 'สัปดาห์ที่ 5',
               dataIndex: 'week5',
               render: (value, record) => {
                  return {
                     props: {
                        style: { background: '#3baea0' },
                     },
                     children: <span>{commaNumber(value)}</span>,
                  };
               },
            },
            {
               title: 'รวม',
               dataIndex: 'total',

               render: (_, record) => {
                  const total = [
                     'week1',
                     'week2',
                     'week3',
                     'week4',
                     'week5',
                  ].reduce((sum, week) => sum + (record[week] || 0), 0);
                  return {
                     props: {
                        style: { background: '#93e4c1' },
                     },
                     children: <span>{commaNumber(total)}</span>,
                  };
               },
            },
         ],
      },
      {
         title: 'ข้อมูลจริง',
         children: [
            // {
            //   title: 'คอลัมน์ 1',
            //   dataIndex: 'total',
            //   render: (_, record) => {
            //     const total = ['week1', 'week2', 'week3', 'week4'].reduce((sum, week) => sum + (record[week] || 0), 0);
            //     return {
            //       props: {
            //         style: {background: '#8dc6ff'}
            //       },
            //       children: <span>{commaNumber(total)}</span>
            //     };
            //   }
            // },
            {
               title: 'หัก ณ ที่จ่าย',
               dataIndex: 'withholdingTax',
               render: (_, record) => {
                  const total = [
                     'week1',
                     'week2',
                     'week3',
                     'week4',
                     'week5',
                  ].reduce((sum, week) => sum + (record[week] || 0), 0);
                  const withholdingTax = total * 0.05; // 5% of total
                  return {
                     props: {
                        style: { background: '#8dc6ff' },
                     },
                     children: (
                        <span>{commaNumber(withholdingTax.toFixed(2))}</span>
                     ),
                  };
               },
            },
            {
               title: 'ค่าโอน',
               dataIndex: 'transferFee',
               render: (_, record) => {
                  const weeksWithData = [
                     'week1',
                     'week2',
                     'week3',
                     'week4',
                     'week5',
                  ].filter((week) => record[week] && record[week] > 1).length;
                  const transferFee = weeksWithData * 30;
                  return {
                     props: {
                        style: { background: '#8dc6ff' },
                     },
                     children: <span>{transferFee}</span>,
                  };
               },
            },
            {
               title: 'ค่าคอม',
               dataIndex: 'commission',
               render: (_, record) => {
                  const total = [
                     'week1',
                     'week2',
                     'week3',
                     'week4',
                     'week5',
                  ].reduce((sum, week) => sum + (record[week] || 0), 0);
                  const withholdingTax = total * 0.05;
                  const weeksWithData = [
                     'week1',
                     'week2',
                     'week3',
                     'week4',
                     'week5',
                  ].filter((week) => record[week] && record[week] > 1).length;
                  const transferFee = weeksWithData * 30;
                  const commission = total - withholdingTax - transferFee;
                  return {
                     props: {
                        style: { background: '#8dc6ff' },
                     },
                     children: (
                        <span>{commaNumber(commission.toFixed(2))}</span>
                     ),
                  };
               },
            },
         ],
      },
   ];

   const header = [
      { label: 'ชื่อ', key: 'user.name' },
      { label: 'เลขบัตรประชาชน', key: 'user.cardId' },
      { label: 'ธนาคาร', key: 'user.bankData.bank' },
      { label: 'เลขบัญชี', key: 'user.bankData.bankAccount' },
      { label: 'สัปดาห์1', key: 'week1' },
      { label: 'สัปดาห์2', key: 'week2' },
      { label: 'สัปดาห์3', key: 'week3' },
      { label: 'สัปดาห์4', key: 'week4' },
      { label: 'สัปดาห์5', key: 'week5' },
      { label: 'รวม', key: 'total' },
      { label: 'หัก ณ ที่จ่าย', key: 'withholdingTax' },
      { label: 'ค่าโอน', key: 'transferFee' },
      { label: 'ค่าคอม', key: 'commission' },
   ];

   let newData = sortedCommissions.map((i, index) => {
      const calculateWeekValue = (value) => {
         if (value === 0) return 0;
         //  return Number((value - (value * 0.05)).toFixed(2)); // 5% deduction, rounded to 2 decimal places
         return value;
      };

      const weeks = ['week1', 'week2', 'week3', 'week4', 'week5'];

      // Calculate new week values
      const newWeekValues = {};
      weeks.forEach((week) => {
         newWeekValues[week] = calculateWeekValue(i[week]);
      });

      // Calculate total
      const total = weeks.reduce((sum, week) => sum + (i[week] || 0), 0);

      // Calculate withholding tax
      const withholdingTax = Number((total * 0.05).toFixed(2));

      // Calculate transfer fee
      const weeksWithData = weeks.filter(
         (week) => i[week] && i[week] > 1
      ).length;
      const transferFee = weeksWithData * 30;

      // Calculate commission
      const commission = Number(
         (total - withholdingTax - transferFee).toFixed(2)
      );

      return {
         index,
         ...i,
         ...newWeekValues,
         total: Number(total.toFixed(2)),
         withholdingTax,
         transferFee,
         commission,
      };
   });

   console.log('data:', newData);

   return (
      <DefaultLayout>
         <div>
            <Button>
               <CSVLink
                  filename={'รายงานส่งบัญชี.csv'}
                  data={newData}
                  headers={header}
               >
                  Export Data
               </CSVLink>
            </Button>
         </div>
         <br />
         <br />
         <div>
            <Table bordered columns={columns} dataSource={newData} />
         </div>
      </DefaultLayout>
   );
};

export default SummaryCommissionUsersPerMonth;
