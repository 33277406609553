import React from 'react'
import DefualtLayoutKh from '../components/DefualtLayoutKh'

const Home = () => {
  return (
    <DefualtLayoutKh>
        <div>
            KH Home
        </div>
    </DefualtLayoutKh>
  )
}

export default Home