import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userService from '../users/userService';
import centerService from './centerService';
import * as api from './api';
import Cookies from 'js-cookie';

const initialState = {
   centers: [],
   center: {},
   isError: false,
   isSuccess: false,
   isLoading: false,
   message: '',
};

// Create new center
export const createCenter = createAsyncThunk(
   'center/create',
   async (centerData, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await centerService.createCenter(centerData, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// Get centers
export const getCenters = createAsyncThunk(
   'center/getAlls',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await centerService.getCenters(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// GET MY CENTER
export const getCenter = createAsyncThunk(
   'center/getCenterByid',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await centerService.getCenter(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// GET CENTER BY ID
export const getCenterId = createAsyncThunk(
   'center/getCenterByid',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await centerService.getCenterId(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// UPDATE CENTER
// export const updateCenter = createAsyncThunk(
//    'center/updateCenter',
//    async (centerId,centerData, thunkAPI) => {
//       try {
//          const token = thunkAPI.getState().auth.userInfo.token;
//          console.log(centerData);
//          return await centerService.updateCenter(centerId,centerData, token);
//       } catch (error) {
//          const message =
//             (error.response &&
//                error.response.data &&
//                error.response.data.message) ||
//             error.message ||
//             error.toString();
//          return thunkAPI.rejectWithValue(message);
//       }
//    }
// );

// UPDATE CENTER
export const updateCenter = createAsyncThunk(
   'center/updateCenter',
   async ({ centerId, values }, { rejectWithValue }) => {
      console.log(centerId);
      console.log(values);
      try {
         const res = await api.updateCenter(centerId, values);
         return res.data;
      } catch (error) {
         return rejectWithValue(error.res.data);
      }
   }
);

// Delete center
export const deleteCenter = createAsyncThunk(
   'center/delete',
   async (id, thunkAPI) => {
      try {
         const token = thunkAPI.getState().Cookies.get('token');
         return await centerService.deleteCenter(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const centerSlice = createSlice({
   name: 'center',
   initialState,
   reducers: {
      reset: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(createCenter.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(createCenter.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.centers.push(action.payload);
         })
         .addCase(createCenter.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getCenter.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getCenter.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.center = action.payload;
         })
         .addCase(getCenter.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getCenters.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getCenters.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.centers = action.payload;
         })
         .addCase(getCenters.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(updateCenter.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(updateCenter.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.centers = action.payload;
         })
         .addCase(updateCenter.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(deleteCenter.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(deleteCenter.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.centers = state.centers.filter(
               (center) => center._id !== action.payload.id
            );
         })
         .addCase(deleteCenter.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         });
   },
});

export const { reset } = centerSlice.actions;
export default centerSlice.reducer;
