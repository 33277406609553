import axios from 'axios';


 // const API_URL = 'http://localhost:5000/api/commission/';

let API_URL = ''
if(process.env.NODE_ENV === 'development') {
   API_URL = 'http://localhost:8000/api/commission/';
} else {
   API_URL =  '//api.admin-siamprai.com/api/commission/';

}
//const API_URL = 'http://localhost:8000/api/commission';

const getMatchingData = async (date,token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
      // params: data,
   };

   const res = await axios.get(
      API_URL + `getMatchingData/${date}`,
      config
   );
   return res.data;
};

const getCommission = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL, config);
   return response.data;
};

const getSumBinary = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + '/getsumbinary', config);
   return response.data;
};

const getComMobile = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + '/getComMobile', config);
   return response.data;
};

const getRecLine = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + '/getrecline', config);
   return response.data;
};

const getRecommendCommission = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + '/recpoint', config);
   return response.data;
};

//GET BY USERS
const getByUsers = async (users, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.post(API_URL + '/getusers', users, config);
   return response.data;
};

// ADMIN
const getBonusByDates = async (dates,token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };
   const response = await axios.get(API_URL + `/getBonusByDates/${dates.sDate}/${dates.eDate}`, config);
   return response.data;
}

const commissionService = {
   getRecommendCommission,
   getCommission,
   getByUsers,
   getRecLine,
   getSumBinary,
   getComMobile,
   getBonusByDates,
   getMatchingData
};

export default commissionService;
