import axios from 'axios';

 // const API_URL = 'http://localhost:5000/api/product/';

let API_URL = ''
if(process.env.NODE_ENV === 'development') {
   API_URL = 'http://localhost:8000/api/product/';
} else {
   API_URL =  '//api.admin-siamprai.com/api/product/';

}

const getProducts = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL, config);
   return response.data;
};

const getProducts2 = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'getProducts', config);
   return response.data;
};

const getProductsAutoShip = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'autoship', config);
   return response.data;
};

const createProduct = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.post(API_URL, data, config);
   return response.data;
};

const addProdctAmount = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.patch(API_URL + `addProdctAmount/${data._id}`, data, config);
   return response.data;
};

const updateProduct = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.put(
      API_URL + 'updateProduct/' + data._id,
      data,
      config
   );
   return response.data;
};

const deleteProduct = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.delete(API_URL + 'delete/' + data, config);
   return response.data;
};

// PRODUCT PROMOTION

const createProductPromotion = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.post(API_URL + 'autoship', data, config);
   return response.data;
};

const productPromotion = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const res = await axios.put(API_URL + 'updatepromotion/' + id, {}, config);

   return res.data;
};

const productService = {
   createProduct,
   getProducts,
   productPromotion,
   getProductsAutoShip,
   updateProduct,
   deleteProduct,
   createProductPromotion,
   addProdctAmount,
   getProducts2
};

export default productService;
