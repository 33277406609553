import { Button, Card, Col, Form, Input, InputNumber, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { BiDollarCircle, BiExport, BiTransfer } from 'react-icons/bi';
import { FaPercentage } from 'react-icons/fa';
import { GiMoneyStack } from 'react-icons/gi';

import Loading from '../components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import DefaultLayout from '../components/DefualtLayout';
import {
   updateSetting,
   getSetting,
   createSetting,
} from '../features/setting/settingSlice';
import { MdPriceCheck } from 'react-icons/md';

const SettingPercentage = () => {
   const [openModal, setOpenModal] = useState(false);
   const [editItem, setEditItem] = useState(null);
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const location = useLocation();

   const [isTax, setIsTax] = useState(false);
   const [isTransferFee, setIsTransferFee] = useState(false);
   const [isKeyPrice, setIsKeyPrice] = useState(false);
   const [isPV, setIsPV] = useState(false);

   const { userInfo } = useSelector((state) => state.auth);
   const { settings, isLoading, updateSuccess } = useSelector(
      (state) => state.setting
   );

  
   let keyPrice = null;
   let transferFee = null;
   let tax = null;
   let PV = null
   let banks = []
   if (settings) {

      banks = settings.filter((e) => e.name === 'bank')
      

      // spPrice = settings.filter((e) => e.name === 'spPrice');
      PV = settings
      .filter((obj) => obj.name.includes('PV'))
      .map((obj) => obj.number);

      keyPrice = settings
         .filter((obj) => obj.name.includes('keyPrice'))
         .map((obj) => obj.number);

      transferFee = settings
         .filter((obj) => obj.name.includes('transferFee'))
         .map((obj) => obj.number);

      tax = settings
         .filter((obj) => obj.name.includes('tax'))
         .map((obj) => obj.number);
   }
   const [loadigPage, setLoadingPage] = useState(true);

   let transferFeeInt = parseInt(transferFee);
   let taxInt = parseInt(tax);
   let keyPriceInt = parseInt(keyPrice);
   let PVInt = parseInt(PV);

   useEffect(() => {

      if(userInfo.isSuperAdmin){
         navigate('/admin/dashboard')
      }
      dispatch(getSetting());
   }, [updateSuccess, dispatch]);

   const onFinish = (value) => {
      console.log(editItem);
      // dispatch(updateSetting(value));

      if (editItem === null) {
         console.log(value);
         dispatch(createSetting(value));
      } else {
         console.log(value);
         dispatch(updateSetting(value));
      }
   };

   const handleModal = (value) => {
     // setOpenModal(true);

     

    if (value === 'keyPrice') {
         setIsKeyPrice(true);
         setEditItem({ name: 'ราคา / 1รหัส', number: keyPriceInt });
      }  else if (value === 'isTax') {
         setIsTax(true);
         setEditItem({ name: 'ภาษี', number: taxInt });
         console.log(editItem);
      } else if (value === 'isTransferFee') {
         setIsTransferFee(true);
         setEditItem({ name: 'ค่าโอน', number: transferFeeInt });
         console.log(editItem);
      }
      else if (value === 'PV') {
         setIsPV(true);
         setEditItem({ name: 'ราคา PV / 1รหัส', number: PVInt });
         console.log(editItem);
      }
   };

   return (
      <DefaultLayout>
         <Container>
            <div>
               <Button
                  htmlType='submit'
                  type='primary'
                  onClick={() => setOpenModal(true)}>
                  {' '}
                  สร้าง setting{' '}
               </Button>
            </div>
            <br />
            <br />
            {isLoading ? (
               <>
                  <Loading />
               </>
            ) : (
               <>
                  <div className='text-center'>
                     <Row gutter={[26, 30]} style={{ fontWeigth: 'bold' }}>

                     <Col sm={24} xs={24} md={8} lg={6}>
                           {' '}
                           <Card
                              onClick={() => handleModal('PV')}
                              hoverable
                              title={
                                 <>
                                    {' '}
                                    <span style={{ color: ' #f8da5b' }}>
                                       <MdPriceCheck />
                                    </span>{' '}
                                 </>
                              }>
                              <h4> ราคา PV / 1 รหัส </h4>
                              <h5 className='font-weight-bold'> {PV} </h5>
                           </Card>
                        </Col>

                        <Col sm={24} xs={24} md={8} lg={6}>
                           {' '}
                           <Card
                              onClick={() => handleModal('keyPrice')}
                              hoverable
                              title={
                                 <>
                                    {' '}
                                    <span style={{ color: ' #f8da5b' }}>
                                       <MdPriceCheck />
                                    </span>{' '}
                                 </>
                              }>
                              <h4> ราคา / 1 รหัส </h4>
                              <h5 className='font-weight-bold'> {keyPrice} บาท </h5>
                           </Card>
                        </Col>

                      

                      
                        <Col sm={24} xs={24} md={8} lg={6}>
                           <Card
                              onClick={() => handleModal('isTransferFee')}
                              hoverable
                              title={
                                 <>
                                    {' '}
                                    <span style={{ color: ' #f95959' }}>
                                       <BiTransfer />
                                    </span>{' '}
                                 </>
                              }>
                              <h4> ค่าโอน  </h4>
                              <h5 className='font-weight-bold'>
                                 {' '}
                                 {transferFee} <span> บาท</span>
                              </h5>
                           </Card>
                        </Col>
                        <Col sm={24} xs={24} md={8} lg={6}>
                           {' '}
                           <Card
                              onClick={() => handleModal('isTax')}
                              hoverable
                              title={
                                 <>
                                    {' '}
                                    <span style={{ color: '#afc5ff' }}>
                                       <GiMoneyStack />
                                    </span>{' '}
                                 </>
                              }>
                              <h4> ภาษีการโอน </h4>
                              <h5 className='font-weight-bold'> {tax}% </h5>
                           </Card>
                        </Col>
                     </Row>
                  </div>
               </>
            )}
         </Container>

         {openModal && (
            <Modal
               title={`
                ${isTax === true ? 'แก้ไข ภาษี' : ''}
               ${isTransferFee === true ? 'แก้ไขค่าโอน' : ''}  
                ${isKeyPrice === true ? 'ราคา / 1 รหัส' : ''}  
                ${isPV === true ? 'PV / 1 รหัส' : ''}  
               `}
               visible={openModal}
               footer={false}
               onCancel={() => {
                  setOpenModal(null);
                  setIsTax(false);
                  setIsTransferFee(false);
                  setIsKeyPrice(false);
                  setIsPV(false)
               }}>
               <Form
                  initialValues={editItem}
                  layout='vertical'
                  onFinish={onFinish}>
               {editItem === true ? (
                  null
               ) :(
                  <Form.Item name='name' label='ชื่อ'>
                  <Input  />
               </Form.Item>
               )}
                  <Form.Item name='number' label='ตัวเลข'>
                     <InputNumber style={{ width: '100%' }} />
                  </Form.Item>

                  <div className='d-flex justify-content-end'>
                     <Button
                        loading={isLoading}
                        htmlType='submit'
                        type='primary'>
                        {' '}
                        SAVE
                     </Button>
                  </div>
               </Form>
               {/* <Form
                  labelCol={{
                     span: 6,
                  }}
                  wrapperCol={{
                     span: 18,
                  }}
                  onFinish={onFinish}>
                  {isSp ? (
                     <>
                        <Form.Item name='spPrice' label='Sp'>
                           <InputNumber
                              style={{ width: '100%' }}
                              placeholder={spPrice}
                           />
                        </Form.Item>
                     </>
                  ) : null}
                  {isSpPercent ? (
                     <>
                        <Form.Item name='spPercent' label='spPercent'>
                           <InputNumber
                              style={{ width: '100%' }}
                              placeholder={spPercent}
                           />
                        </Form.Item>
                     </>
                  ) : null}
                  {isTax ? (
                     <>
                        <Form.Item name='tax' label='ภาษี'>
                           <InputNumber
                              style={{ width: '100%' }}
                              placeholder={tax}
                           />
                        </Form.Item>
                     </>
                  ) : null}
                  {isTransferFee ? (
                     <>
                        <Form.Item name='transferFee' label='ค่าโอน'>
                           <InputNumber
                              style={{ width: '100%' }}
                              placeholder={transferFee}
                           />
                        </Form.Item>
                     </>
                  ) : null}

                  <div className='d-flex justify-content-end'>
                     <Button htmlType='submit' type='primary'>
                        {' '}
                        SAVE
                     </Button>
                  </div>
               </Form> */}
            </Modal>
         )}
      </DefaultLayout>
   );
};

export default SettingPercentage;
