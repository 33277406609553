import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userAdminService from './userAdminService';
import Cookies from 'js-cookie';

const initialState = {
   users: [],
   centers: [],
   user: {},
   upline: {},
   isError: false,
   isSuccess: false,
   isUpdateSuccess: false,
   deleteIsSuccess: false,
   isLoading: false,
   message: '',
};

// Delete users by id
export const deleteUsers = createAsyncThunk(
   'user/deleteusers',
   async (ids, thunkAPI) => {
      try {
         const token = Cookies.get('token');

         return await userAdminService.deleteUsers(ids, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const deleteUser = createAsyncThunk(
   'user/deleteuser',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');

         return await userAdminService.deleteUser(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const BanUser = createAsyncThunk(
   'user/BanUser',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userAdminService.BanUser(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);
export const UnBanUser = createAsyncThunk(
   'user/UnBanUser',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userAdminService.UnBanUser(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);


// UPDATE USER
export const updateUser = createAsyncThunk(
   'user/updateUser',
   async (userData, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userAdminService.updateUser(userData, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);
export const editRecommendUser = createAsyncThunk(
   'user/editRecommendUser',
   async (userData, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userAdminService.editRecommendUser(userData, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// getUsersSumPerMonth
export const getUsersSumPerMonth = createAsyncThunk(
   'user/getUsersSumPerMonth',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userAdminService.getUsersSumPerMonth(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getUsersPower = createAsyncThunk(
   'user/getUsersPower',
   async ( thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userAdminService.getUsersPower(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);
export const getDirector = createAsyncThunk(
   'user/getDirector',
   async ( thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userAdminService.getDirector(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const findUserByUserId = createAsyncThunk(
   'user/findUserByUserId',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userAdminService.findUserByUserId(id,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);
export const findUserByUserData = createAsyncThunk(
   'user/findUserByUserData',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userAdminService.findUserByUserData(data,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

const userAdminSlice = createSlice({
   name: 'userAdmin',
   initialState,
   reducers: {
      reset: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(getUsersSumPerMonth.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getUsersSumPerMonth.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.users = action.payload;
         })
         .addCase(getUsersSumPerMonth.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getUsersPower.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getUsersPower.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.users = action.payload;
         })
         .addCase(getUsersPower.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getDirector.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getDirector.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.users = action.payload;
         })
         .addCase(getDirector.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(findUserByUserId.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(findUserByUserId.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.user = action.payload;
         })
         .addCase(findUserByUserId.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(findUserByUserData.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(findUserByUserData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.users = action.payload;
         })
         .addCase(findUserByUserData.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(updateUser.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(updateUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.user = action.payload;
         })
         .addCase(updateUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(editRecommendUser.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(editRecommendUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
         })
         .addCase(editRecommendUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(deleteUsers.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(deleteUsers.fulfilled, (state, action) => {
            state.isLoading = false;
            state.deleteIsSuccess = true;
            state.users = state.users.filter(
               (user) => user._id !== action.payload.id
            );
         })
         .addCase(deleteUsers.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(deleteUser.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(deleteUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.deleteIsSuccess = true;
            state.users = state.users.filter(
               (user) => user._id !== action.payload.id
            );
         })
         .addCase(deleteUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(BanUser.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(BanUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isUpdateSuccess = true;
            state.user =action.payload
         })
         .addCase(BanUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(UnBanUser.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(UnBanUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isUpdateSuccess = true;
            state.user =action.payload
         })
         .addCase(UnBanUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         });
   },
});

export const { reset } = userAdminSlice.actions;
export default userAdminSlice.reducer;
