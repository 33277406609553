import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { store } from './store';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
 <Router basename='/'>
   <ConfigProvider
   theme={{
    token: {
      fontFamily: `'Noto Sans Thai', sans-serif`
    }
   }}
   >
   <App />
   </ConfigProvider>
  </Router>
</Provider>
);

