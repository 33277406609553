// axiosConfig.js
import axios from 'axios';

import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

// Determine API URL based on environment
let API_URL = '';
if (process.env.NODE_ENV === 'development') {
  API_URL = 'http://localhost:8000/api/order/';
} else {
  API_URL = '//api.admin-siamprai.com/api/order/';
}


// Configure Axios
axios.defaults.baseURL = API_URL;
axios.defaults.withCredentials = true; // Ensure credentials are sent with requests

// Interceptor to handle 401 error and logout
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {

        console.log('401');
        
        originalRequest._retry = true;

        console.log('Dispatching logout action'); // Debugging log



      localStorage.clear();
      Cookies.remove('token');
      axios.defaults.headers.common['Authorization'] = '';

      const navigate = useNavigate()
      navigate('/login')
    }
    return Promise.reject(error);
  }
);

export default axios;
