import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { AiFillEdit } from 'react-icons/ai';
import { MdDeleteForever } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';
import {
  createProductPromotion,
  deleteProduct,
  getProductsAutoShip,
  updateProduct,
} from '../features/product/productSlice';
import DefaultLayout from '../components/DefualtLayout';

const ProductPromotion = () => {
  const [ModalOpen, setModalOpen] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [media, setMedia] = useState(null);
  const [mediaPreview, setMediaPreview] = useState(null);

  const { userInfo } = useSelector((state) => state.auth);
  const {
     products,
     isLoading,
     isError,
     isSuccess,
     getLoading,
     getSuccess,
     updateSuccess,
     createSuccess,
  } = useSelector((state) => state.product);

  console.log(products);

  useEffect(() => {
     dispatch(getProductsAutoShip());
  }, [dispatch, updateSuccess, createSuccess, isSuccess]);

  const onFinish = async (values) => {
     if (media !== null) values.picUrl = media;

     if (editItem === null) {
        console.log(values);
        dispatch(createProductPromotion(values));
        setModalOpen(false);
        setMedia(null);
     } else {
        values._id = editItem._id;
        console.log(values);
        dispatch(updateProduct(values));
        setModalOpen(false);
        setMedia(null);
     }
  };

  const handleProductImageUpload = (e) => {
     const file = e.target.files[0];

     TransformFileData(file);
  };

  const TransformFileData = (file) => {
     const reader = new FileReader();

     if (file) {
        reader.readAsDataURL(file);
        reader.onloadend = () => {
           setMedia(reader.result);
        };
     } else {
        setMedia('');
     }
  };

  const deleteHandler = (id) => {
     let resultConfirm = window.confirm('ต้องการลบสินค้า ?');
     if (resultConfirm == true) {
        console.log('Is Yes');
        dispatch(deleteProduct(id));
     } else {
        console.log('Is Cancle');
     }
  };

  return (
     <DefaultLayout>
        {isLoading ? (
           <Loading />
        ) : (
           <Container>
              <div
                 className='d-flex'
                 style={{ justifyContent: 'space-evenly' }}>
                 <Button
                    type='primary'
                    htmlType='submit'
                    onClick={() => setModalOpen(true)}>
                    เพิ่มสินค้า
                 </Button>
                 <Link to='/admin/productlistpromotion'>
                    <Button
                       style={{
                          backgroundColor: 'green',
                          color: 'white',
                       }}
                       htmlType='submit'
                       // onClick={() => navigate('/admin/productlistpromotion')}
                    >
                       รายการสินค้าโปรโมชั่นแลก Auto Ship
                    </Button>
                 </Link>
              </div>
              <br />
              {products.length >= 1 ? (
                 <div>
                    <br />
                    <br />
                    <div>
                       <Row gutter={[100, 30]}>
                          {products.map((product) => {
                             return (
                                <Col
                                   key={product.id}
                                   sm={24}
                                   xs={24}
                                   md={24}
                                   lg={10}
                                   xl={8}>
                                   <Card
                                      size='big'
                                      style={{ width: 300 }}
                                      cover={
                                         <img
                                            alt='example'
                                            style={{
                                               height: 200,
                                               objectFit: 'cover',
                                            }}
                                            src={product.picUrl.url}
                                         />
                                      }
                                      actions={[
                                         <AiFillEdit
                                            style={{ fontSize: '32px' }}
                                            onClick={() => {
                                               setEditItem(product);
                                               setModalOpen(true);
                                            }}
                                         />,
                                         <MdDeleteForever
                                            onClick={() =>
                                               deleteHandler(product._id)
                                            }
                                            style={{ fontSize: '32px' }}
                                         />,
                                      ]}>
                                      <h4 className='fw-bold'>
                                         {' '}
                                         {product.name}
                                      </h4>
                                      <p className='fw-light fs-6'>
                                         {' '}
                                         {product.desc}{' '}
                                      </p>
                                      <h5 className='fw-bold'>
                                         {' '}
                                         {product.price}{' '}
                                      </h5>

                                      <Row
                                         justify='space-between'
                                         style={{
                                            textAlign: 'center',
                                            alignItems: 'center',
                                         }}>
                                         <Col span={12}>
                                            <h2>
                                               {/* <EditButton
                                               onClick={() => {
                                                  setEditItem(product);
                                                  setAddEditModalOpen(true);
                                               }}>
                                               {' '}
                                               แก้ไข{' '}
                                            </EditButton> */}
                                            </h2>
                                         </Col>

                                         <Col span={12}>
                                            <h2>
                                               {/* <DeleteButton
                                               onClick={() =>
                                                  console.log('delte')
                                               }>
                                               {' '}
                                               ลบ{' '}
                                            </DeleteButton> */}
                                            </h2>
                                         </Col>
                                      </Row>
                                   </Card>
                                </Col>
                             );
                          })}
                       </Row>
                    </div>
                    <br />
                 </div>
              ) : (
                 <h1> ไม่มีสินค้าโปรโมชั่น </h1>
              )}
           </Container>
        )}
        {ModalOpen && (
           <Modal
              title={`${editItem !== null ? 'Edit Item' : 'Add new Item'}`}
              visible={ModalOpen}
              footer={false}
              onCancel={() => {
                 setEditItem(null);
                 setModalOpen(false);
              }}>
              <Form
                 initialValues={editItem}
                 layout='vertical'
                 onFinish={onFinish}>
                 {editItem !== null && (
                    <div className='d-flex justify-content-center my-3'>
                       <Image
                          className='m-auto'
                          preview={false}
                          height={150}
                          width={150}
                          src={editItem.picUrl.url}
                       />
                    </div>
                 )}

                 <Form.Item name='name' label='ชื่อ'>
                    <Input />
                 </Form.Item>

                 <Form.Item name='price' label='ราคา'>
                    <InputNumber style={{ width: '100%' }} />
                 </Form.Item>

                 <Form.Item name='desc' label='รายละเอียดสินค้า'>
                    <Input />
                 </Form.Item>

                 <input
                    id='upload'
                    name='imgUpload'
                    accept='image/*'
                    type='file'
                    onChange={handleProductImageUpload}
                 />
                 <div className='d-flex justify-content-center my-3'>
                    <Image
                       className='m-auto'
                       preview={false}
                       height={100}
                       width={100}
                       src={media}
                    />
                 </div>

                 <Form.Item name='category' label='Category'>
                    <Select>
                       <Select.Option value='cream'>ครีม </Select.Option>
                       <Select.Option value='inhaler'> ยาดม </Select.Option>
                    </Select>
                 </Form.Item>

                 <div className='d-flex justify-content-end'>
                    <Button
                       loading={isLoading}
                       htmlType='submit'
                       type='primary'>
                       {' '}
                       SAVE
                    </Button>
                 </div>
              </Form>
           </Modal>
        )}
     </DefaultLayout>
  );
};

export default ProductPromotion;
