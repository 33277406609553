import React from 'react'
import DefaultLayoutKh from '../components/DefualtLayoutKh'

const ExportBonusSelectDayKh = () => {
  return (
    <DefaultLayoutKh>
        <div>
            <h1>
            ExportBonusSelectDayKh  
            </h1>
        </div>
    </DefaultLayoutKh>
  )
}

export default ExportBonusSelectDayKh