import axios from 'axios';
import Cookies from 'js-cookie';
const API = axios.create({ baseURL: '/api/center' });

API.interceptors.request.use((req) => {
   // if (localStorage.getItem('userInfo')) {
   //    req.headers.Authorization = `Bearer ${
   //       JSON.parse(localStorage.getItem('userInfo')).token
   //    }`;
   // }

   if (Cookies.get('token')) {
      req.headers.Authorization = `Bearer ${
         JSON.parse(Cookies.get('token')).token
      }`;
   }
   return req;
});

export const updateCenter = (centerId, values) =>
   API.put(`/${centerId}`, values);
